import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";
import housesBG from "../images/bitmaps/houses-bg.jpg";
const IconGridSectionStyled = styled.div`
  background-image: ${(props) => props.page == "home" && `url(${housesBG})`};
  background-size: cover;
  background-position: center;
  ${(props) => props.page == "home" && `  padding: 4rem 0;`}

  text-align: center;
  ${(props) => props.page == "home" && "color:white;"}
  margin-bottom: 3rem;
  h2 {
    color: white;
  }
  .divider {
    border-bottom: 2px solid white;
    width: 40px;
    display: inline-block;
    margin: auto;
    margin-bottom: 2rem;
  }
  .col {
    margin-bottom: 2.25rem;
    img {
      margin-bottom: 1.25rem;
      height: 95px;
      height: 80px;
      ${(props) => props.page == "home" && "height: 95px"}
    }
  }

  @media ${gvars.device.tablet} {
    .col-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .col {
      width: 30%;
      max-width: 325px;
    }
  }
  @media ${gvars.device.laptop} {
    ${(props) => props.page == "home" && `    padding: 5rem 0;`}
    margin-bottom: 5rem;
    .col-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .col {
        width: 33%;
      }
    }
  }
`;

export default class IconGridSection extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <IconGridSectionStyled page={this.props.page}>
        {/*<BlackOverlay page={this.props.page} /> */}
        <div className="outer-grid desktop-inner-grid inner-container">
          {
            this.props
              .children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
          }
        </div>
      </IconGridSectionStyled>
    );
  }
}
