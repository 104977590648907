import { createGlobalStyle } from "styled-components/macro";
import * as gvars from "../global-styles/GlobalVariables";
const GlobalStyle = createGlobalStyle`


@font-face {
    font-family: 'Roboto';
    src: url('./fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('./fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}



@font-face {
    font-family: 'Roboto';
    src: url('./fonts/roboto-light-webfont.woff2') format('woff2'),
         url('./fonts/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Roboto';
    src: url('./fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('./fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

*{
    box-sizing:border-box;

}
html{
    font-size:100%;
}

body{
    font-size:18px;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    margin:0;
}
body.menu-expanded{
    overflow:hidden;
}



/**********
 * FONTS
 ********/

h1,h2,h3,h4,h5,p,li,span,ul{
    margin-top:0;

}

h1{
font-size:2.25rem;
font-weight:300;
margin-bottom:1.5rem;
}

h2{
font-size:1.5rem;
font-weight:bold;
margin-bottom:1rem;
text-align:center;
color:${gvars.colours.blue};
}

h3{
    color:${gvars.colours.blue};  
font-size:1.25rem;
text-transform:uppercase;
margin-bottom:0.75rem;
}

h4{
}

p.large{

}
p,li{
font-size:1.125rem;
line-height: 1.625rem;
}

// Buttons
.white-button{
    text-decoration:none;
    color:white;
    border:1px solid white;
    border-radius:8px;
    padding:1rem;
    display: inline-block;
    transition: all 0.1s;
}

.white-button:hover{
    background: rgba(255,255,255,0.2);
}

.footer{padding-top:0;}






@media ${gvars.device.tablet}{
    h1{
        font-size: 3.15rem;
    }
	
}

@media ${gvars.device.desktopL}{

	
}


  // MODAL STUFF
  .ReactModal__Overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 18 44 / 75%) !important;
  }
  .ReactModal__Content {

  }
  .modal-inner-content-container {
    border-radius: 14px;
    background: white;
    padding: 20px;

    .title-container{
        display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:2rem;
        h2{
            margin:0;
        }
        img{
            width: 24px;
            cursor:pointer;
        }
    }
    input{
        width: 100%;
    display: block;
    margin-bottom: 1rem;
    height: 54px;

    border-radius: 6px;
    border: 1px solid #cfcfcf;
    padding: 0 16px;
    }
    input[type=submit]{
        margin-top:2rem;
        background:#135691;
        transition: all 0.2s;
        color:white;
        border:none;
        font-weight:bold;
    }

    input[type=submit]:hover{
        background:${gvars.colours.blue};
    }
  }


/**********
 * GRIDS
 ********/

.outer-grid{
    max-width:500px;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:20px;
    padding-right:20px;
}



.footer{
  
}


/*************
 * TABLET QUERIES
*************/

@media ${gvars.device.tablet} {



    .outer-grid{
  
        width: 87%;
        max-width: 1000px;
        padding-left: 0;
    padding-right: 0;
    }

    .tablet-inner-grid{
        width: 65%;
        max-width: 768px;
    }


}
/*************
 * DESKTOP QUERIES
*************/
@media ${gvars.device.laptop} {

    html{
        //font-size: 1.1vw;
    }

    body{
        font-size:16px;
    }
    
h1{
    font-size: 3.65rem;
    line-height: 4.1rem;
}

h2{
    font-size: 1.5rem;

}
p,li{
    font-size: 1.063rem;
    line-height: 1.525rem;
}
p.desktop-large{

}


    .outer-grid{
width:90%;
max-width:none;
    }

    .desktop-inner-grid{
        width: 76%;
    max-width: 1160px;
    }

    //MODAL STFF
    .ReactModal__Overlay{

  }
  .ReactModal__Content {
    width: 33%;
    max-width:630px;
  }
  .modal-inner-content-container {

    padding: 40px;
    .title-container{

    }


  }
   


}

/*****
1336px QUERIES
 */

@media ${gvars.device.laptopL} {
    html{
    
    }
    h1{
        font-size: 4.15rem;
    line-height: 4.5rem;
    }
    h2{
        font-size: 1.7rem;
    }
}

/*************
 * 1080p QUERIES
*************/

@media ${gvars.device.desktopL} {
    html{
     
    }

    p.large{
        font-size:  ${gvars.sizes.mSize}; 
    }
    .outer-grid{
        

    }

    .desktop-inner-grid{
    

    }

}

/****
ULTRA LARGE QUERIES
 */
@media ${gvars.device.desktopLL} {
    html{
    
        
    }

    .outer-grid{

    }

}


`;

export default GlobalStyle;
