import React, { useState } from "react";
import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import Modal from "react-modal";
import styled from "styled-components/macro";
import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";
import { Squash as Hamburger } from "hamburger-react";
import { HashLink } from "react-router-hash-link";

import logo from "../images/logo.svg";
import menuButton from "../images/icons/menu-button.svg";
import menuButtonClose from "../images/icons/menu-button-close.svg";

const HeaderStyled = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  header {
    &.header--sticky {
      .header__logo {
        width: 35%;
      }
    }
  }

  background: white;
  -webkit-box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 12%);
  box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 12%);
  // This is the visible part of the header before anyone clicks the hamburger icon on mobile
  .front-nav-container {
    height: 81px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;

    .left-col-container {
      height: 100%;
      a {
        height: 100%;
      }
    }
    .desktop-links-container {
      display: none;
    }

    img.logo {
      height: 100%;
    }
    img.menu-icon {
      height: 64%;
      &.hidden {
      }
    }
    img.menu-icon-close {
      height: 64%;
      display: none;
    }
  }

  // This is the mobile nav links container
  .mobile-nav-container {
    // When hidden
    transition: opacity 0.3s;
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    visibility: hidden;
    padding-top: 40px;
    //When visible
    &.visible {
      visibility: visible;
      opacity: 1;
    }
    color: white;
    background: rgb(30, 36, 120);
    background: linear-gradient(
      144deg,
      rgba(30, 36, 120, 1) 0%,
      rgba(21, 80, 126, 1) 100%
    );
    // The actual mobile nav links inner container
    .inner-container {
      a {
        color: white;
        text-decoration: none;
        display: block;
        font-size: 1.25rem;
        margin-bottom: 1.75rem;
        @media screen and (max-height: 600px) {
          margin-bottom: 1.25rem;
        }
      }
      .divider {
        border-bottom: 1px solid white;
        width: 30px;
        margin-bottom: 1.75rem;
      }
    }
  }

  @media ${gvars.device.laptop} {
    header {
      .header__logo {
        width: 15vw;
      }
      .header__container {
      }

      &.header--sticky {
        .header__logo {
          width: 10.6vw !important;
        }
      }
    }
    .about-us-dropdown-container {
      display: none;
      position: absolute;
      top: 4.5rem;
      background: white;

      margin-left: 41px;
      a {
        margin-right: 0 !important;
        text-align: left;
        display: block !important;

        padding: 17px;
      }
      a:hover {
        background: #e2e2e2;
      }
    }
    .about-us-link:hover {
      .about-us-dropdown-container {
        display: block !important;
      }
    }

    img.logo {
      margin-right: 3rem;
    }
    .hamburger-react {
      display: none;
    }
    .front-nav-container {
      height: 72px;
      padding-top: 0;
      padding-bottom: 0;
      .logo-container {
        display: flex;
        align-items: center;
      }
      img.logo {
        height: 74%;
      }
      .desktop-links-container {
        display: flex;
        align-items: center;
        height: 100%;
        a {
          text-decoration: none;
          color: ${gvars.colours.blue};
          margin-right: 1rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media ${gvars.device.laptopL} {
          a {
            margin-right: 2rem;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 1rem;
          color: ${gvars.colours.blue};
          &:first-of-type {
            margin-right: 2rem;
          }
        }
        .divider {
          height: 35px;
          width: 1px;
          border-right: 1px solid #e6e6e6;
          margin-right: 1rem;
        }
        @media ${gvars.device.laptopL} {
          .divider {
            margin-right: 2rem;
          }
        }
      }
      .left-col-container {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Header = (props) => {
  const [stickyState, setSticky] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  let timer;

  window.onscroll = () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      // If window is scrolled down, add sticky class to header. Otherwise remove it.
      // console.log(window.scrollY);
      setSticky(window.pageYOffset > 100);
    }, 100);
  };

  const sticky = props.fixedTopNav ? true : stickyState;

  const menuOpen = () => {
    //Runs when the menu opens
    setNavOpen(true);
    document.body.style.overflow = "hidden";
  };

  const menuClose = () => {
    //Runs when the menu closes
    setNavOpen(false);
    document.body.style.overflow = "auto";
  };

  // SIGN IN MODAL STUFF

  Modal.setAppElement("#root");

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <HeaderStyled>
        <header
          className={`header ${sticky ? "header--sticky" : ""} ${
            navOpen ? "header--navOpen" : ""
          }`}
        >
          <div className="outer-grid front-nav-container">
            <div className="left-col-container">
              <Link to="/" className="logo-container">
                {" "}
                <img className="logo" src={logo} alt="BPS Logo" />
              </Link>

              {/* DESKTOP LINKS */}
              <div className="desktop-links-container">
                <Link to="/">Home</Link>
                <Link to="/about-us" className="about-us-link">
                  About Us
                  <div className="about-us-dropdown-container">
                    <Link to="/bps-online">BPS Online</Link>
                    <Link to="/our-services">Our Services</Link>
                  </div>
                </Link>

                <HashLink smooth to="/#contact-us-section">
                  Contact Us
                </HashLink>
              </div>
            </div>

            <div className="right-col-container">
              <Hamburger
                color="#1B2077"
                toggled={navOpen}
                onToggle={(toggled) => {
                  if (toggled) {
                    // close menu
                    menuOpen();
                  } else {
                    menuClose();
                  }
                }}
              />
              <div className="desktop-links-container">
                <Link to="#" onClick={openModal}>
                  Sign In
                </Link>

                <a href="https://bpsltd-secure.dwellant.com/Account/Register">
                  Register
                </a>
                <div className="divider"></div>
                <p>Need to report a problem?</p>
                <p>
                  <b>01628 522 888</b>
                </p>
              </div>
            </div>
          </div>

          {/* MOBILE NAV CONTAINER */}
          <div className={`mobile-nav-container ${navOpen ? "visible" : ""}`}>
            <div className="inner-container outer-grid">
              <div>
                <Link to="/" onClick={menuClose}>
                  Home
                </Link>
                <Link to="/about-us" onClick={menuClose}>
                  About Us
                </Link>
                <Link to="/bps-online" onClick={menuClose}>
                  BPS Online
                </Link>
                <Link to="/our-services" onClick={menuClose}>
                  Services
                </Link>
                <HashLink to="/#contact-us-section" onClick={menuClose}>
                  Contact Us
                </HashLink>

                <div className="divider"></div>
                <Link
                  to="/"
                  onClick={() => {
                    menuClose();
                    openModal();
                  }}
                >
                  Sign In
                </Link>
                <a href="https://bpsltd-secure.dwellant.com/Account/Register">
                  Register
                </a>
              </div>

              <div className="divider"></div>
              <p>
                <b>Need to report a problem?</b>
                <br></br>
                <b>01628 522 888</b>
              </p>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            className={"ReactModal__Content outer-grid"}
          >
            <div className="modal-inner-content-container">
              <div className="title-container">
                <div></div>
                <h2>Sign In</h2>

                <img
                  src={menuButtonClose}
                  alt="Menu button close"
                  onClick={closeModal}
                />
              </div>

              <form
                method="post"
                action="https://bpsltd-secure.dwellant.com/Account/LogOn"
                className="sign-in-form"
              >
                <input
                  type="text"
                  id="displayNameOrEmailAddress"
                  placeholder="Email or Display Name"
                  name="displayNameOrEmailAddress"
                />
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                />
                <input
                  id="button"
                  type="submit"
                  value="Sign In"
                  class="SignInBtn"
                />
              </form>
            </div>
          </Modal>
        </header>
      </HeaderStyled>
    </>
  );
};
