import React, { useEffect } from "react";
import { Header } from "../components/Header";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import objectFitImages from "object-fit-images";

import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";
import OpeningSection from "../components/OpeningSection";
import ContentRow from "../components/ContentRow";
import IconGridSection from "../components/IconGridSection";

/***
 * IMAGES
 */

import BPSOnlineBG from "../images/bitmaps/bps-online-bg.jpg";
import IntroIllus from "../images/illustrations/everything.svg";
import SecurIllus from "../images/illustrations/secure.svg";

// Icons gridded on the second section
import support from "../images/icons/support.svg";
import megaphone from "../images/icons/megaphone_1.svg";
import tasks from "../images/icons/tasks.svg";
import vote from "../images/icons/vote.svg";
import call from "../images/icons/call2.svg";
import documents from "../images/icons/documents.svg";
import mobileAlert from "../images/icons/mobile-alert.svg";

const BPSOnlineWrapper = styled.div`
  // Second section 3 col grid
`;

const BPSOnline = () => {
  useEffect(() => {
    objectFitImages();
  });

  return (
    <>
      <Header />
      <Fade>
        <BPSOnlineWrapper>
          <OpeningSection page={"aboutus"} bgImage={BPSOnlineBG}>
            <div className="text-container">
              <h1>BPS Online</h1>
              <div className="divider"></div>
            </div>
          </OpeningSection>

          <ContentRow>
            <div className="title-container">
              <h2>Access All Your Information In One Place</h2>
              <div className="divider"></div>
            </div>
            <img
              className="image-col"
              src={IntroIllus}
              alt="Logos of our credentials"
            />
            <div className="text-col">
              <div className="title-container">
                <h2>Access All Your Information In One Place</h2>
                <div className="divider"></div>
              </div>
              <p>
                By definition, our job means that we are rarely office-bound –
                and that can cause challenges when we need to keep you updated
                about issues in your development. Messages and email become
                onerous to track and manage and we can easily become
                over-whelmed – at which stage, our service could suffer.
              </p>
              <p>
                To avoid this our website enables two-way communication about
                building news, maintenance issues and allows you to access the
                information you need, when you need it.
              </p>
            </div>
          </ContentRow>

          <IconGridSection page="bps-online">
            <div className="col-container">
              <div className="col">
                <img src={support} alt="support icon" />
                <p>
                  When things go wrong in your building, send us online requests
                  when it suits you to get the problem resolved.
                </p>
              </div>

              <div className="col">
                <img src={megaphone} alt="megaphone icon" />
                <p>Get regular news and updates about your building.</p>
              </div>

              <div className="col">
                <img src={tasks} alt="tasks icons" />
                <p>
                  Keep track of requests and see how we are progressing. You can
                  also see if issues have been reported by other residents.
                </p>
              </div>

              <div className="col">
                <img src={vote} alt="vote icons" />
                <p>
                  Vote and collaborate about group decisions online, helping us
                  to get your feedback and get things done more quickly.
                </p>
              </div>

              <div className="col">
                <img src={call} alt="call icons" />
                <p>
                  In emergencies, you can reach your neighbours quickly via
                  email and text message – whilst keeping all contact details
                  private.
                </p>
              </div>

              <div className="col">
                <img src={documents} alt="documents icons" />
                <p>
                  The building documents you need, like insurance cover, budgets
                  and accounts are all stored online ready for you to access
                  when you’re ready.
                </p>
              </div>

              <div className="col">
                <img src={mobileAlert} alt="mobile alert icon" />
                <p>
                  Receive notification by email of planned activities and how
                  they affect you. Urgent items can be sent direct to your
                  mobile phone by text.
                </p>
              </div>
            </div>
          </IconGridSection>

          <ContentRow>
            <div className="title-container">
              <h2>Confidential and Efficient</h2>
              <div className="divider"></div>
            </div>
            <img
              className="image-col"
              src={SecurIllus}
              alt="Illustration of security"
            />
            <div className="text-col">
              <div className="title-container">
                <h2>Confidential and Efficient</h2>
                <div className="divider"></div>
              </div>
              <p>
                Most importantly, your details are kept highly secure. You can
                choose how frequently you receive updates and we will never give
                your neighbours your contact details.{" "}
              </p>

              <p>
                Other residents see only your display name, which you choose. So
                that we can contact you about urgent news (a power cut or
                security issue for example), it's best if you can share your
                email and / or mobile number. So register today to take
                advantage of all our online service has to offer.
              </p>
            </div>
          </ContentRow>
        </BPSOnlineWrapper>
      </Fade>
      <Footer></Footer>
      <GlobalStyle />
    </>
  );
};

export default BPSOnline;
