import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";

import { HashLink } from "react-router-hash-link";

const FooterStyled = styled.div`
  background: ${gvars.colours.blue};
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: white;
  a {
    color: white;
    text-decoration: none;
    display: block;
    margin-bottom: 1.75rem;
  }
  p {
    font-size: 0.875rem;
    color: white;
    margin-top: 4rem;
    margin-bottom: 0;
  }

  @media ${gvars.device.laptop} {
    footer {
      display: flex;
      justify-content: space-between;
      a,
      p {
        margin: 0;
      }
      a {
        margin-right: 1rem;
      }
      .left-col {
        display: flex;
      }
    }
  }
`;

const Footer = (props) => {
  return (
    <FooterStyled>
      <footer className="footer outer-grid">
        <div className="left-col">
          <Link to="/">Home</Link>
          <Link to="/about-us">About Us</Link>

          <HashLink to="/#contact-us-section">Contact Us</HashLink>
        </div>

        <p>© 2022 BANNER PROPERTY SERVICES LIMITED, ALL RIGHTS RESERVED.</p>
      </footer>
    </FooterStyled>
  );
};

export default Footer;
