import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";

const ContentRowStyled = styled.div`
  margin-bottom: 4rem;
  ${(props) => props.bg == true && "margin-bottom:0;"}
  ${(props) =>
    props.bg == true &&
    "background: rgb(30,36,120);background: linear-gradient(144deg, rgba(30,36,120,1) 0%, rgba(19,50,119,1) 100%);color:white;"}

  ${(props) => props.bg == true && "padding: 4rem 0;"}

  h2 {
    ${(props) => props.bg == true && "color:white;"}
  }
  .divider {
    border-bottom: 2px solid ${gvars.colours.blue};
    ${(props) => props.bg == true && "border-bottom-color:white;"}
    width: 50px;
    margin: auto;
    margin-bottom: 2rem;
  }

  img {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 500px;
  }

  .text-col {
    .title-container {
      display: none; // Display none on mobile and then show on tablet+
    }
  }

  .right-col {
    order: 3;
    flex: 1 0 auto;
    .inner-container {
      display: flex;
    }
    .col {
      margin-right: 2rem;
      &:last-of-type {
        margin-right: 0;
      }
    }
    h2 {
      text-align: left;
      color: black;

      font-weight: bold;
      margin-bottom: 0;
      font-size: 2.5rem;
      font-size: 3rem;
    }
    p {
      color: black;
      font-weight: 300;
      font-size: 1.275rem;
    }

    @media ${gvars.device.tablet} {
      margin-left: 3rem;
    }
  }

  @media ${gvars.device.tablet} {
    .title-container {
      display: none;
    }
    .inner-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-col {
      width: 46%;

      max-width: 500px;
      ${(props) => props.right == true && "  width:auto;max-width:unset;"}
      order: 1;
      ${(props) => props.page == "about-us" && "  margin-right: 3rem;"}

      .title-container {
        display: block; // Display none on mobile and then show on tablet+
        h2 {
          text-align: left;
        }
        .divider {
          margin-left: 0;
          margin-right: 0;
          border-width: 3px;
        }
      }
    }
    img {
      order: 2;
      width: 46%;
      ${(props) => props.right == true && " order:1;margin-right:3rem;"}
    }
  }
  @media ${gvars.device.laptop} {
    margin-bottom: 8rem;
    ${(props) => props.bg == true && "margin-bottom:0;"}
    img {
      width: 46%;
      margin-left: 0em;
      margin-bottom: 0;
    }
    .right-col {
      h2 {
        font-size: 2.75rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }
  @media ${gvars.device.laptopL} {
    img {
      width: 46%;
      margin-left: 6rem;
      ${(props) => props.right == true && " margin-left:0;"}
    }
  }
`;

export default class ContentRow extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <ContentRowStyled
        right={this.props.right}
        page={this.props.page}
        bg={this.props.bg}
      >
        {/*<BlackOverlay page={this.props.page} /> */}
        <div className="inner-content outer-grid desktop-inner-grid">
          {
            this.props
              .children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
          }
        </div>
      </ContentRowStyled>
    );
  }
}
