import React from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useLayoutEffect } from "react";
import Index from "./pages/Index";
import AboutUs from "./pages/about-us";
import BPSOnline from "./pages/bps-online";
import Services from "./pages/services";

import NoMatch from "./pages/NoMatch";
import Footer from "./components/Footer";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Router basename="/">
      <Wrapper>
        <div>
          <Routes>
            <Route path="/" exact element={<Index />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route path="/bps-online" exact element={<BPSOnline />} />
            <Route path="/our-services" exact element={<Services />} />
            <Route component={NoMatch} />
          </Routes>
        </div>
      </Wrapper>
    </Router>
  );
}

export default App;
