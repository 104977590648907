import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";

const OpeningSectionStyled = styled.div`
  height: 80vh;
  ${(props) => props.page == "aboutus" && "height: 80vh;"}
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;

  display: flex;
  -ms-flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  padding-top: 2rem;
  margin-bottom: 2.5rem;

  .inner-content {
    .text-container {
      ${(props) => props.page == "aboutus" && "  text-align: center;"}
    }
    p {
      font-size: 1.063rem;
    }
    .divider {
      border-bottom: 1px solid white;
      width: 40px;
      margin-bottom: 1.5rem;
      ${(props) =>
        props.page == "aboutus" && "margin-left: auto;margin-right: auto;"}
    }
  }

  @media ${gvars.device.tablet} {
    height: 60vh;
    margin-bottom: 3.5rem;
    .inner-content {
      p {
        br {
          display: none;
        }
        max-width: 47%;
      }
      .divider {
        border-bottom: 2px solid white;
        width: 60px;
      }
    }
  }
  @media ${gvars.device.laptop} {
    height: 100vh;
    ${(props) => props.page == "aboutus" && " height:80vh;"}
    margin-bottom: 4.5rem;
    .inner-content {
      p {
        max-width: 387px;
      }
    }
  }
  @media ${gvars.device.laptopL} {
  }
`;

const BlackOverlay = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  background-color: black;
  background-color: #000306;
  opacity: 0.8;
  // ${(props) => props.page == "conciergehv" && "opacity: 0.85;"}
  @media ${gvars.device.laptop} {
    ${(props) => props.page == "home" && "opacity: 0.82;"}
  }
`;

export default class OpeningSection extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  render() {
    return (
      <OpeningSectionStyled page={this.props.page} bgImage={this.props.bgImage}>
        {/*<BlackOverlay page={this.props.page} /> */}
        <div className="inner-content outer-grid desktop-inner-grid">
          {
            this.props
              .children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
          }
        </div>
      </OpeningSectionStyled>
    );
  }
}

// export default OpeningSection;
