import React, { useEffect } from "react";
import { Header } from "../components/Header";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import objectFitImages from "object-fit-images";

import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";
import OpeningSection from "../components/OpeningSection";
import ContentRow from "../components/ContentRow";

/***
 * IMAGES
 */

import aboutUsBg from "../images/bitmaps/about-us-bg.jpg";
import working from "../images/illustrations/working.svg";
import credentials from "../images/illustrations/credentials.png";

const AboutUsWrapper = styled.div``;

const AboutUs = () => {
  useEffect(() => {
    objectFitImages();
  });

  return (
    <>
      <Header />
      <Fade>
        <AboutUsWrapper>
          <OpeningSection page={"aboutus"} bgImage={aboutUsBg}>
            <div className="text-container">
              <h1>About Us</h1>
              <div className="divider"></div>
            </div>
          </OpeningSection>

          <ContentRow>
            <div className="title-container">
              <h2>Our Approach</h2>
              <div className="divider"></div>
            </div>

            <div className="text-col">
              <div className="title-container">
                <h2>Our Approach</h2>
                <div className="divider"></div>
              </div>
              <p>
                BPS have been a dedicated residential block management since
                1983 and now manage close to a 100 developments across the Home
                Counties on behalf of a variety of right to manage companies,
                enfranchised apartment blocks and commercial ground rent
                investors.
              </p>
            </div>

            <div className="right-col">
              <div className="inner-container">
                <div className="col">
                  <h2>35+</h2>
                  <p>Years of service</p>
                </div>
                <div className="col">
                  <h2>80+</h2>
                  <p>Developments</p>
                </div>
              </div>
            </div>
          </ContentRow>

          <ContentRow right>
            <div className="title-container">
              <h2>Our Philosophy</h2>
              <div className="divider"></div>
            </div>

            <img
              className="image-col"
              src={working}
              alt="Illustation of person working at desk"
            />
            <div className="text-col">
              <div className="title-container">
                <h2>Our Philosophy</h2>
                <div className="divider"></div>
              </div>
              <p>
                Our philosophy is based around taking the time to listen
                carefully to the needs of clients, offer practical solutions and
                wherever possible, anticipate the required actions to avoid last
                minute panics. We understand that residents need to know what’s
                happening where they live – and how their service charge is
                being spent.
              </p>
              <p>
                Mindful of the busy lives that we all seem to lead these days,
                this website is designed to provide a constantly available
                resource to enhance communication and provide easy access to the
                documentation most often requested.
              </p>
            </div>
          </ContentRow>

          <ContentRow page="about-us">
            <div className="title-container">
              <h2>Our Credentials</h2>
              <div className="divider"></div>
            </div>

            <img
              className="image-col"
              src={credentials}
              alt="Logos of our credentials"
            />
            <div className="text-col">
              <div className="title-container">
                <h2>Our Credentials</h2>
                <div className="divider"></div>
              </div>
              <p>
                As a professional managing agent, the company has been a member
                of the Association of Residential Managing Agents (ARMA) since
                2000, has staff who are either a member, or studying to be a
                member, of the Institute of Residential Property Management
                (IRPM), subscribes to the Surveyors Ombudsman Service.
              </p>
              <p>
                The company is also listed with The Property Ombudsman service.
              </p>
            </div>
          </ContentRow>

          <Footer></Footer>
          <GlobalStyle />

          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>BPS Property Management</title>
            <meta name="title" content="BPS Property Management" />
            <meta
              name="description"
              content="BPS Property Services are a specialist residential block management company operating throughout the Home Counties."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="http://www.bpsltd.co.uk" />
            <meta property="og:title" content="BPS Property Management" />
            <meta
              property="og:description"
              content="BPS Property Services are a specialist residential block management company operating throughout the Home Counties."
            />

            {/*
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
            */}
            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="http://www.bpsltd.co.uk" />
            <meta property="twitter:title" content="BPS Property Management" />
            <meta
              property="twitter:description"
              content="BPS Property Services are a specialist residential block management company operating throughout the Home Counties."
            />
            {/*  <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
         />*/}
          </Helmet>
        </AboutUsWrapper>
      </Fade>
    </>
  );
};

export default AboutUs;
