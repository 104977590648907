import React, { useEffect } from "react";
import { Header } from "../components/Header";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import objectFitImages from "object-fit-images";

import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";
import OpeningSection from "../components/OpeningSection";
import ContentRow from "../components/ContentRow";
import IconGridSection from "../components/IconGridSection";

import ContactUsSection from "../components/ContactUsSection";

/***
 * IMAGES
 */

import background from "../images/bitmaps/home-bg.jpg";
import london from "../images/illustrations/london.svg";
import housesBG from "../images/bitmaps/houses-bg.jpg";
import megaphone from "../images/icons/megaphone.svg";
import customerService from "../images/icons/customer-service.svg";
import listIcon from "../images/icons/list.svg";

/***
 * IMAGES
 */
// import logo from "../Images/Product Logos/dwellant-logo.svg";

const titleText = "London’s Residential Specialists"; // Title for the second section, needed as a const as its used twice

const HomeWrapper = styled.div`
  // BPS ONLINE SECTION
  .bps-online-section {
    background-image: url(${housesBG});
    background-size: cover;
    background-position: center;
    padding: 4rem 0;
    text-align: center;
    color: white;
    margin-bottom: 3rem;
    h2 {
      color: white;
    }
    .divider {
      border-bottom: 2px solid white;
      width: 40px;
      display: inline-block;
      margin: auto;
      margin-bottom: 2rem;
    }
    .col {
      margin-bottom: 2.25rem;
      img {
        margin-bottom: 1.25rem;
        height: 95px;
      }
    }

    @media ${gvars.device.laptop} {
      padding: 5rem 0;
      margin-bottom: 5rem;
      .col-container {
        display: flex;
        justify-content: space-between;
      }
      .col {
        width: 33%;
        max-width: 325px;
      }
    }
  }
`;

const Home = () => {
  useEffect(() => {
    objectFitImages();
  });

  return (
    <>
      <Header />
      <Fade>
        <HomeWrapper>
          <OpeningSection page={"home"} bgImage={background}>
            <div className="text-container">
              <h1>
                BPS Property <br />
                Management
              </h1>
              <div className="divider"></div>
              <p>
                ARMA certified and<br></br> managing your residential <br></br>
                blocks since 1983.
              </p>
            </div>
          </OpeningSection>

          <ContentRow>
            <div className="title-container">
              <h2>{titleText}</h2>
              <div className="divider"></div>
            </div>

            <img className="image-col" src={london} alt="London counties" />
            <div className="text-col">
              <div className="title-container">
                <h2>{titleText}</h2>
                <div className="divider"></div>
              </div>
              <p>
                BPS Property Services are a specialist residential block
                management company operating throughout the Home Counties.
              </p>

              <p>
                Our role is to manage the communal areas of residential
                developments on behalf of freeholders and leaseholders.{" "}
              </p>

              <p>
                We have a small but dedicated team of property professionals
                backed up by specialist retained consultants.{" "}
              </p>
            </div>
          </ContentRow>

          <IconGridSection page="home">
            <h2>BPS Online</h2>
            <div className="divider"></div>
            <div className="col-container">
              <div className="col">
                <img src={megaphone} alt="Megaphone icon" />
                <p>Get regular news and updates about your building.</p>
              </div>

              <div className="col">
                <img src={customerService} alt="customerService icon" />
                <p>
                  When things go wrong in your building, send us online requests
                  when it suits you to get the problem resolved.
                </p>
              </div>

              <div className="col">
                <img src={listIcon} alt="listIcon " />
                <p>
                  Keep track of requests and see how we are progressing. You can
                  also see if issues have been reported by other residents.
                </p>
              </div>
            </div>
            <Link className="white-button" to="/bps-online">
              Learn More
            </Link>
          </IconGridSection>

          <ContactUsSection></ContactUsSection>
          <Footer></Footer>
          <GlobalStyle />

          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>BPS Property Management</title>
            <meta name="title" content="BPS Property Management" />
            <meta
              name="description"
              content="BPS Property Services are a specialist residential block management company operating throughout the Home Counties."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="http://www.bpsltd.co.uk" />
            <meta property="og:title" content="BPS Property Management" />
            <meta
              property="og:description"
              content="BPS Property Services are a specialist residential block management company operating throughout the Home Counties."
            />

            {/*
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
            */}
            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="http://www.bpsltd.co.uk" />
            <meta property="twitter:title" content="BPS Property Management" />
            <meta
              property="twitter:description"
              content="BPS Property Services are a specialist residential block management company operating throughout the Home Counties."
            />
            {/*  <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
         />*/}
          </Helmet>
        </HomeWrapper>
      </Fade>
    </>
  );
};

export default Home;
