import React from "react";
import styled from "styled-components/macro";

import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";

//Images

const ContactUsSectionStyled = styled.div`
  .mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 100%;
    margin-bottom: 3rem;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 100%;
  }
  iframe {
    width: 100%;
  }
  .divider {
    border-bottom: 2px solid ${gvars.colours.blue};
    width: 40px;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .contact-info-boxes-container {
    .col {
      margin-bottom: 3rem;
    }
    .divider {
      width: 48px;
      margin-bottom: 1.25rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media ${gvars.device.tablet} {
    .contact-info-boxes-container {
      display: flex;
      justify-content: space-between;
      .divider {
        margin-bottom: 1rem;
      }
      .col {
        width: 20%;
      }
    }
  }

  @media ${gvars.device.laptop} {
    .divider {
      margin-bottom: 4rem;
    }
  }
`;

export default class ContactUsSection extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <ContactUsSectionStyled id="contact-us-section">
        <h2>Contact Us</h2>
        <div className="divider"></div>

        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              height="500"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Cock%20and%20Rabbit%20House,%20HP16%209LZ&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>

        <div className="contact-info-boxes-container outer-grid desktop-inner-grid">
          <div className="col">
            <h3>Address</h3>
            <div className="divider"></div>
            <p>
              Banner Property Services,
              <br />
              Cock and Rabbit House,
              <br />
              The Lee,
              <br />
              Great Missenden,
              <br />
              Buckinghamshire,
              <br />
              HP16 9LZ
              <br />
            </p>
          </div>

          <div className="col">
            <h3>PO Box</h3>
            <div className="divider"></div>
            <p>
              PO Box 979
              <br />
			  GREAT MISSENDEN
              <br />
              HP16 6DL
            </p>
          </div>

          <div className="col">
            <h3>Telephone</h3>
            <div className="divider"></div>
            <p>
              01628 522888
            </p>
          </div>

          <div className="col">
            <h3>Email</h3>
            <div className="divider"></div>
            <p>mail@bpsltd.co.uk</p>
          </div>
        </div>
        <h3></h3>
      </ContactUsSectionStyled>
    );
  }
}

// export default OpeningSection;
