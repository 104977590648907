import React from "react";
import Fade from "react-reveal/Fade";

import { Header } from "../components/Header";

const NoMatch = () => {
  return (
    <>
      <Header />
      <Fade></Fade>
    </>
  );
};

export default NoMatch;
