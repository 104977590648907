import React, { useEffect } from "react";
import { Header } from "../components/Header";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import objectFitImages from "object-fit-images";

import * as gvars from "../global-styles/GlobalVariables";
import GlobalStyle from "../global-styles/GlobalStyles";
import OpeningSection from "../components/OpeningSection";
import ContentRow from "../components/ContentRow";
import IconGridSection from "../components/IconGridSection";

/***
 * IMAGES
 */

import ServicesBG from "../images/bitmaps/services-bg.jpg";
import ServicesIllus from "../images/illustrations/services.svg";
import AfterHoursIllus from "../images/illustrations/after-hours.svg";

const ServicesWrapper = styled.div`
  // Second section 3 col grid
`;

const Services = () => {
  useEffect(() => {
    objectFitImages();
  });

  return (
    <>
      <Header />
      <Fade>
        <ServicesWrapper>
          <OpeningSection page={"aboutus"} bgImage={ServicesBG}>
            <div className="text-container">
              <h1>Services</h1>
              <div className="divider"></div>
            </div>
          </OpeningSection>

          <ContentRow>
            <div className="title-container">
              <h2>We offer a full range of Property Management Services</h2>
              <div className="divider"></div>
            </div>
            <img
              className="image-col"
              src={ServicesIllus}
              alt="Illustration of people using services"
            />
            <div className="text-col">
              <div className="title-container">
                <h2>We offer a full range of Property Management Services</h2>
                <div className="divider"></div>
              </div>
              <ul>
                <li>
                  Collection of lessees ground rents and service charge
                  contributions
                </li>
                <li>Managing service charge arrears in a timely manner</li>
                <li>Solid accounting records</li>
                <li>Preparation of the service charge budget</li>
                <li>Regular site visits by your property manager</li>
                <li>Managing quote, specification and engagement</li>
                <li>Process of contractors for minor works</li>
                <li>
                  Employment and supervision of caretaking, porterage staff and
                  cleaners
                </li>
                <li>Consultation regarding major items of repair</li>
                <li>General advice on Insurance and Management matters</li>
                <li>Maintaining and updating Health & Safety records</li>
              </ul>
            </div>
          </ContentRow>

          <ContentRow bg>
            <div className="title-container">
              <h2>After Hours' Services</h2>
              <div className="divider"></div>
            </div>
            <img
              className="image-col"
              src={AfterHoursIllus}
              alt="Illustration of clock on fire"
            />
            <div className="text-col">
              <div className="title-container">
                <h2>After Hours' Services</h2>
                <div className="divider"></div>
              </div>
              <p>
                If you need to contact us in an emergency out of normal office
                hours, please dial 0843 6597023 to speak with our specialised
                call handling service.
              </p>

              <p>
                Please note that an emergency is defined as a situation that
                poses an immediate risk to health, life or property. To report
                non-urgent issues out of hours, please send us a Request by
                logging into the website.
              </p>
              <p>
                Our normal office hours are Monday to Friday, 9am to 5pm
                (excluding bank/public holidays).
              </p>
            </div>
          </ContentRow>
        </ServicesWrapper>
      </Fade>
      <Footer></Footer>
      <GlobalStyle />
    </>
  );
};

export default Services;
